import AdminLayout from '@/layouts/AdminLayout.vue'
import {authenticationStore} from '@/main'
import PageNotFound from '@/views/PageNotFound.vue'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import {useUserStore} from '@/stores/UserStore'
import {isDev, isLocal, setPageTitle} from '@/plugins/helpers'
import {useLandingsStore} from '@/views/tracker/landings/LandingsStore'

function beforeEach(to, from, next) {
  if (!authenticationStore.isAuthenticated) {
    return next()
  }

  if (!authenticationStore.hasTwoFA && authenticationStore.isPoint2WebEmployee && to.name !== 'two-fa') {
    return next({name: 'two-fa'})
  }

  setPageTitle(to)

  next()
}

export {beforeEach}

export default {
  path: '/',
  name: 'content',
  component: AdminLayout,
  beforeEnter: (to, from, next) => {
    if (!authenticationStore.isAuthenticated) {
      next({name: 'auth.login'})
    }

    if (!authenticationStore.hasTwoFA && authenticationStore.isPoint2WebEmployee && to.name !== 'two-fa') {
      return next({name: 'two-fa'})
    }

    if (authenticationStore.isCoreTeam) {
      let {getCompanies} = useCompanyStore()
      let {loadEmployees} = useUserStore()
      let {getUnits} = useLandingsStore()

      getCompanies()
      loadEmployees()
      getUnits()
    } else if (isLocal()) {
      let {loadEmployees} = useUserStore()

      loadEmployees()
    } else if (authenticationStore.isDeveloper) {
      let {getUnits} = useLandingsStore()

      getUnits()
    }

    next()
  },

  redirect: {
    name: 'home',
  },

  children: [
    // Dashboard
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        title: 'Dashboard',
      },
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next({name: 'home'}) : next()
      },
    },
    // Dashboard
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home-page/HomePageView.vue'),
      meta: {
        title: 'Home Page',
      },
      beforeEnter: (to, from, next) => {
        !authenticationStore.isPoint2WebEmployee ? next() : next({name: 'ad-account-requests'})
      },
    },

    // User Profile
    {
      path: 'user',
      name: 'user',
      redirect: {
        name: 'user.profile',
      },
      children: [
        {
          path: 'profile',
          name: 'user.profile',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'My Profile',
            parent: 'user',
          },
        },
        {
          path: 'notifications',
          name: 'user.notifications',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'Notifications',
            parent: 'user',
          },
        },
        {
          path: 'security',
          name: 'user.security',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'Security',
            parent: 'user',
          },
        },
      ],
    },
    {
      path: 'user-settings',
      name: 'user.settings',
      redirect: {
        name: 'user.settings.users.list',
      },
      children: [
        {
          path: 'users-list',
          name: 'user.settings.users.list',
          component: () => import('@/views/user-main/settings/UserSettings.vue'),
          meta: {
            title: 'Users',
            parent: 'user.settings',
          },
        },
      ],

      beforeEnter: (to, from, next) => {
        authenticationStore.isManager || authenticationStore.isAgencyClient
          ? next()
          : next({name: 'ad-account-requests'})
      },
    },

    {
      path: 'two-fa',
      name: 'two-fa',
      component: () => import('@/views/auth/TwoFaForm.vue'),
      meta: {
        title: 'Setup two-factor authentication',
      },
    },

    // Ad account requests
    {
      name: 'ad-account-requests',
      path: 'ad-account-requests',
      alias: 'meta-ad-account-requests',
      redirect: {
        name: 'ad-account-requests.accounts.list',
      },
      children: [
        {
          path: 'my-requests',
          name: 'my-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'My requests',
          },
          beforeEnter: (to, from, next) => {
            !authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
          },
        },
        {
          path: 'accounts',
          name: 'ad-account-requests.accounts.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Account Requests',
          },
        },
        {
          path: 'rename',
          name: 'ad-account-requests.rename.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'Rename Account Requests',
          },
        },
        {
          path: 'top-up',
          name: 'ad-account-requests.top-up.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'Top-up Account Requests',
          },
        },
        {
          path: 'facebook-pages-requests',
          name: 'ad-account-requests.facebook-pages-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'Facebook Page Requests',
          },
        },
        {
          path: 'white-list',
          name: 'white-list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'White List Requests',
          },
        },
        {
          path: 'share-permissions/:hash?',
          name: 'share-permissions',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests.accounts.list',
            title: 'Share Permissions',
          },
        },
        {
          path: 'creative-requests/list',
          name: 'creative-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Creatives Moderation',
            parent: 'ad-account-requests.accounts.list',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isComplianceManager ? next() : next({name: 'home'})
          },
        },
        {
          path: 'client-pre-moderation/list',
          name: 'client-pre-moderation.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Client Pre-Moderation',
            parent: 'ad-account-requests.accounts.list',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isComplianceManager ? next() : next({name: 'home'})
          },
        },

        // Meta  accounts request
        {
          path: 'meta/:id?',
          name: 'meta-ad-account-request',
          alias: 'meta-ad-account-requests/:id?',
          component: () => import('@/views/ad-account-requests/accounts/meta/MetaAdAccountRequest.vue'),
          meta: {
            title: 'Meta',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Google accounts request
        {
          path: 'google/:id?',
          name: 'google-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/google/GoogleAdAccountRequest.vue'),
          meta: {
            title: 'Google',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Tiktok accounts request
        {
          path: 'tiktok/:id?',
          name: 'tiktok-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/tiktok/TiktokAdAccountRequest.vue'),
          meta: {
            title: 'Tiktok',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Bigo accounts request
        {
          path: 'bigo/:id?',
          name: 'bigo-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/bigo/BigoAdAccountRequest.vue'),
          meta: {
            title: 'Bigo',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Bing accounts request
        {
          path: 'bing/:id?',
          name: 'bing-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/bing/BingAdAccountRequest.vue'),
          meta: {
            title: 'Bing',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Snapchat accounts request
        {
          path: 'snapchat/:id?',
          name: 'snapchat-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/snapchat/SnapchatAdAccountRequest.vue'),
          meta: {
            title: 'Snapchat',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },

        // Revcontent accounts request
        {
          path: 'revcontent/:id?',
          name: 'revcontent-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/revcontent/RevcontentAdAccountRequest.vue'),
          meta: {
            title: 'Revcontent',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },
        // Newsbreak accounts request
        {
          path: 'newsbreak/:id?',
          name: 'newsbreak-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/newsbreak/NewsbreakAdAccountRequest.vue'),
          meta: {
            title: 'Newsbreak',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },
        // Taboola accounts request
        {
          path: 'taboola/:id?',
          name: 'taboola-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/taboola/TaboolaAdAccountRequest.vue'),
          meta: {
            title: 'Taboola',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },
        // Outbrain accounts request
        {
          path: 'outbrain/:id?',
          name: 'outbrain-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/outbrain/OutbrainAdAccountRequest.vue'),
          meta: {
            title: 'Outbrain',
            parent: 'ad-account-requests.accounts.list',
            type: 'form',
          },
        },
      ],
    },
    {
      path: 'creatives',
      name: 'creatives',
      redirect: {
        name: 'creatives.list',
      },
      children: [
        {
          path: 'list',
          name: 'creatives.list',
          component: () => import('@/views/creatives/AllAdCreativeRequest.vue'),
          meta: {
            title: 'Creatives Moderation',
            parent: 'creatives',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isAgency ||
            authenticationStore.isAgencyClient ||
            authenticationStore.isComplianceManager
              ? next()
              : next({name: 'home'})
          },
        },
        {
          path: 'approved-creatives',
          name: 'approved-creatives',
          component: () => import('@/views/creatives/AllAdCreativeRequest.vue'),
          meta: {
            title: 'Approved Creatives',
            parent: 'creatives',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isAgency ||
            authenticationStore.isAgencyClient ||
            authenticationStore.isComplianceManager
              ? next()
              : next({name: 'dashboard'})
          },
        },
        {
          path: 'profile/:id?',
          name: 'creatives.profile',
          component: () => import('@/views/creatives/AdCreativeRequest.vue'),
          meta: {
            title: 'Creative Moderation Request',
            parent: 'creatives',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isAgency ||
            authenticationStore.isAgencyClient ||
            authenticationStore.isComplianceManager
              ? next()
              : next({name: 'dashboard'})
          },
        },
      ],
    },
    // Clients onboarding
    {
      path: 'onboarding',
      name: 'onboarding',
      redirect: {
        name: 'onboarding.list',
      },
      children: [
        {
          path: 'list',
          name: 'onboarding.list',
          component: () => import('@/views/onboarding/OnboardingList.vue'),
          meta: {
            title: 'Clients Onboarding',
            parent: 'onboarding',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canWorkWithOnboardings ? next() : next({name: 'home'})
          },
        },
        {
          path: 'document/:hash',
          name: 'onboarding.document',
          component: () => import('@/views/onboarding/OnboardingDocument.vue'),
          meta: {
            parent: 'onboarding',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isLegal ? next() : next({name: 'home'})
          },
        },
      ],
    },
    // Companies
    {
      path: 'companies',
      redirect: {
        name: 'companies.list',
      },
      children: [
        {
          path: '',
          name: 'companies.list',
          component: () => import('@/views/companies/CompaniesList.vue'),
          meta: {
            title: 'Companies',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.hasAccessToCompaniesPage ? next() : next({name: 'home'})
          },
        },
        {
          path: 'users',
          name: 'users.list',
          component: () => import('@/views/companies/users/UsersList.vue'),
          meta: {
            title: 'Users',
            parent: 'companies.list',
          },
        },
        {
          path: 'quick-books-customers',
          name: 'quick-books-customers.list',
          component: () => import('@/views/companies/quick-books-customers/QuickBooksCustomersList.vue'),
          meta: {
            title: 'Quick Books Customers',
            parent: 'companies.list',
          },
        },
        {
          path: 'employees',
          name: 'employees.list',
          component: () => import('@/views/companies/employees/EmployeesList.vue'),
          meta: {
            title: 'Employees',
            parent: 'companies.list',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canAddUserToPointboard ? next() : next({name: 'home'})
          },
        },
        {
          path: ':hash/documents',
          name: 'company.documents',
          component: () => import('@/views/companies/documents/CompanyDocuments.vue'),
          meta: {
            title: 'Company Documents',
            parent: 'companies.list',
          },
        },
      ],
    },
    // Assets
    {
      path: 'assets',
      redirect: {
        name: 'assets.business-managers.list',
      },
      children: [
        {
          path: 'business-managers',
          name: 'assets.business-managers.list',
          alias: 'meta-business-managers',

          component: () => import('@/views/assets/AssetsList.vue'),
          meta: {
            title: 'Business Managers',
            parent: 'assets.companies.list',
          },
        },
        {
          path: 'ad-accounts',
          name: 'assets.ad-accounts.list',
          alias: 'meta-ad-accounts',
          component: () => import('@/views/assets/AssetsList.vue'),
          meta: {
            parent: 'assets.companies.list',
            title: 'Ad Accounts',
          },
        },
      ],
    },

    // Invoices
    {
      path: 'invoices',
      redirect: {
        name: 'invoices.list',
      },
      children: [
        {
          path: '',
          name: 'invoices.list',
          component: () => import('@/views/invoices-main/InvoicesMain.vue'),
          meta: {
            title: 'Proforma Invoices',
            parent: 'invoices-main',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canUseInvoices ? next() : next({name: 'home'})
          },
        },
        {
          path: '/payments',
          name: 'payments.list',
          component: () => import('@/views/invoices-main/InvoicesMain.vue'),
          meta: {
            title: 'Payments',
            parent: 'invoices-main',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canUseInvoices ? next() : next({name: 'home'})
          },
        },
        {
          path: '/payments/daily-balance',
          name: 'payments.daily_balance',
          component: () => import('@/views/invoices-main/InvoicesMain.vue'),
          meta: {
            title: 'Daily Balance',
            parent: 'invoices-main',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canUseInvoices ? next() : next({name: 'home'})
          },
        },
        {
          path: '/credit-line',
          name: 'credit-line.list',
          component: () => import('@/views/invoices-main/InvoicesMain.vue'),
          meta: {
            title: 'Credit Line Requests',
            parent: 'invoices-main',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canUseCreditLineList && (isLocal() || isDev()) ? next() : next({name: 'home'})
          },
        },
      ],
    },
    // Campaigns
    {
      path: 'tracker',
      name: 'tracker',
      redirect: {
        name: 'campaigns.list',
      },
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
      },
      children: [
        {
          path: 'campaigns',
          name: 'campaigns.list',
          component: () => import('@/views/tracker/TrackerList.vue'),
          meta: {
            title: 'Campaigns',
            parent: 'tracker',
          },
        },
        {
          path: 'campaigns/create',
          name: 'campaigns.create',
          component: () => import('@/views/tracker/campaigns/profile/CampaignProfile.vue'),
          meta: {
            title: 'New campaign',
            parent: 'tracker',
          },
        },
        {
          path: 'campaigns/:hash',
          name: 'campaigns.edit',
          component: () => import('@/views/tracker/campaigns/profile/CampaignProfile.vue'),
          meta: {
            title: 'Edit campaign',
            parent: 'tracker',
          },
        },

        {
          path: 'landings',
          name: 'landings.list',
          component: () => import('@/views/tracker/landings/LandingsList.vue'),
          meta: {
            title: 'Landings',
            parent: 'tracker',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isDeveloper || authenticationStore.isMediaBuyer ? next() : next({name: 'home'})
          },
        },
        {
          path: 'ab-tests',
          name: 'ab-tests.list',
          component: () => import('@/views/tracker/TrackerList.vue'),
          meta: {
            title: 'A/B tests',
            parent: 'tracker',
          },
        },
        {
          path: 'ab-tests/profile/:hash/:group_id',
          name: 'ab-tests.profile',
          component: () => import('@/views/tracker/ab-tests/AbTestProfile.vue'),
          meta: {
            title: 'A/B test',
            parent: 'tracker',
          },
        },
      ],
    },

    // Tasks
    {
      path: 'tasks',
      name: 'tasks',
      children: [
        {
          path: 'new-landing',
          name: 'tasks.new-landing',
          component: () => import('@/views/tasks/NewLandingTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Landing Page',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateNewLandingTask ? next() : next({name: 'home'})
          },
        },

        {
          path: 'landing-copy',
          name: 'tasks.landing-copy',
          component: () => import('@/views/tasks/LandingCopyTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Exact Landing Clone',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateCopyLandingTask ? next() : next({name: 'home'})
          },
        },
        {
          path: 'ab-test',
          name: 'tasks.ab-test',
          component: () => import('@/views/tasks/ABTestTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'A/B test task',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateABtestTask ? next() : next({name: 'home'})
          },
        },
        {
          path: 'new-creatives-task',
          name: 'tasks.new-creatives-task',
          component: () => import('@/views/tasks/NewCreativesTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'New Creatives task',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateCreativeTask ? next() : next({name: 'home'})
          },
        },
        {
          path: 'short-task',
          name: 'tasks.new-short-task',
          component: () => import('@/views/tasks/NewShortTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'New short task',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateShortTask ? next() : next({name: 'home'})
          },
        },
        {
          path: 'new-smm-task',
          name: 'tasks.new-smm-task',
          component: () => import('@/views/tasks/NewSMMTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'New SMM task',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateSMMTask ? next() : next({name: 'home'})
          },
        },

        {
          path: 'agency-hands-on',
          name: 'tasks.agency-hands-on',
          component: () => import('@/views/tasks/AgencyHandsOnTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Agency Hands-on',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isAgency ? next() : next({name: 'home'})
          },
        },
        {
          path: 'agency-general',
          name: 'tasks.agency-general',
          component: () => import('@/views/tasks/AgencyGeneralTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Agency Client Pre-Moderation',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.canCreateAgencyGeneralTask ? next() : next({name: 'home'})
          },
        },
        {
          path: 'other',
          name: 'tasks.other',
          component: () => import('@/views/tasks/OtherTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Other task',
          },
        },
        {
          path: 'finance-request',
          name: 'tasks.finance-request',
          component: () => import('@/views/tasks/FinanceRequestTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Payment Request',
          },
          beforeEnter: (to, from, next) => {
            authenticationStore.isPoint2WebEmployee ? next() : next({name: 'dashboard'})
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
      },
    },

    // Reports
    {
      path: 'reports',
      children: [
        {
          path: '',
          name: 'reports',
          component: () => import('@/views/reports/ReportsView.vue'),
          meta: {
            title: 'Reports',
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
      },
    },

    // Tools
    {
      path: 'tools',
      name: 'tools',
      children: [
        // Domains
        {
          path: 'domains',
          name: 'domains.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {parent: 'tools'},
          beforeEnter: (to, from, next) => {
            to.meta.title = authenticationStore.fromSFCompany ? 'Ads.com Domains' : 'Domains'

            if (authenticationStore.canUseDomains) {
              setPageTitle(to)
              next()
            } else {
              next({name: 'home'})
            }
          },
        },
        {
          path: 'cr-domains',
          name: 'cr-domains.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {title: 'CR Domains', parent: 'tools'},
          beforeEnter: (to, from, next) => {
            authenticationStore.fromSFCompany ? next() : next({name: 'home'})
          },
        },
        // Links
        {
          path: 'search-feeds-links',
          name: 'search-feeds-links.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {title: 'Links', parent: 'tools'},
          beforeEnter: (to, from, next) => {
            authenticationStore.fromSFCompany || authenticationStore.isDeveloper ? next() : next({name: 'home'})
          },
        },
        // Subscriptions
        {
          path: 'subscriptions',
          name: 'subscriptions.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Subscriptions',
            parent: 'tools',
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
      },
    },
    // Clickup
    {
      path: 'clickup',
      name: 'clickup.list',
      component: () => import('@/views/clickup-list/ClickupLibraryList.vue'),
      meta: {
        title: 'Clickup',
      },
      beforeEnter: (to, from, next) => {
        authenticationStore.isPoint2WebEmployee ? next() : next({name: 'home'})
      },
    },
    // Not Found
    {path: '/:pathMatch(.*)*', name: 'PageNotFound', component: PageNotFound},
  ],
}
